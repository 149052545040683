import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { inject, Pipe, PipeTransform } from '@angular/core';

/**
 *
 * @param value - The price to be formatted
 * @param currency - The currency to be used
 * @export
 * @class PricePipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'evPrice',
})
export class PricePipe implements PipeTransform {
  private currencyPipe = inject(CurrencyPipe);
  private decimalPipe = inject(DecimalPipe);

  private price: number | undefined;
  private currency: string | undefined;

  private priceObject: Price = { value: undefined, currency: undefined };

  transform(flexibleInput: Price | ArrayType | undefined): string | null | undefined {
    if (Array.isArray(flexibleInput)) {
      this.priceObject.value = flexibleInput[0];
      this.priceObject.currency = flexibleInput[1];
    } else if (flexibleInput) {
      this.priceObject = flexibleInput;
    }

    this.price = this.priceObject.value ? Number(this.priceObject.value) : undefined;

    this.currency = this.priceObject.currency ? String(this.priceObject.currency) : undefined;

    if (!this.price) {
      const price = 0;
      /**returns a 0 formatted with proper decimal point and one digit after the decimal point. This is then replaced with '--' => e.g.,'--.--'
       * 'proper decimal point' is the decimal point used in the current locale - currently not implemented
       * */
      const formatted = this.decimalPipe.transform(price, '1.1-1')?.replace(/[0-9]/g, '--');

      return formatted;
    }

    if (
      typeof this.currency !== 'string' ||
      String(this.currency).length !== 3 ||
      /^[a-zA-Z]+$/.test(String(this.currency)) === false
    ) {
      throw new Error(
        'PricePipe::input did not match the expected format for currency, was the input passed in the correct order?',
      );
    }

    return this.currencyPipe.transform(this.price, this.currency);
  }
}

type optionalStringOrNumber = string | number | undefined | null;

export interface Price {
  value?: optionalStringOrNumber;
  currency?: optionalStringOrNumber;
}

type ArrayType = Array<optionalStringOrNumber>;
