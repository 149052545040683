import { Pipe, PipeTransform } from '@angular/core';
import * as DOMPurify from 'dompurify';
import { marked } from 'marked';

@Pipe({
  standalone: true,
  name: 'markdown',
})
export class MarkdownPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    // Use the marked npm plugin to transform markdown to html
    return DOMPurify.sanitize(marked.parse(value));
  }
}
