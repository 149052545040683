import { signal } from '@angular/core';

export class CoreFeedbackMessageService {
  public $notifications = signal<Notification[]>([]);
  public $alertMessage = signal<AlertMessage | null>(null);

  private DEFAULT_DISPLAY_SECONDS = 5; // seconds
  public DEFAULT_ERROR_MESSAGE = $localize`Oops, something went wrong... Please try again later.`;

  public clearAlertMessage(): void {
    this.$alertMessage.set(null);
  }

  public showAlertMessage(
    message: string,
    type: StatusType = 'info',
    displaySeconds = this.DEFAULT_DISPLAY_SECONDS,
  ): void {
    const newAlertMessage: AlertMessage = {
      id: Date.now().toString(),
      message,
      type,
      displaySeconds,
    };
    this.$alertMessage.set(newAlertMessage);
  }

  public showSuccessMessage(message: string): void {
    this.showAlertMessage(message, 'success');
  }

  public showErrorMessage(
    message = this.DEFAULT_ERROR_MESSAGE,
    displaySeconds = this.DEFAULT_DISPLAY_SECONDS,
  ): void {
    this.showAlertMessage(message, 'error', displaySeconds);
  }

  public addNotification(notification: Notification): void {
    this.$notifications.update(notifications => {
      // Don't add the notification if it already exists
      if (!notifications.find(n => n.id === notification.id)) {
        notifications.push(notification);
      }
      return notifications;
    });
  }

  public removeNotificationById(id: string): void {
    this.$notifications.update(notifications => {
      return notifications.filter(notification => notification.id !== id);
    });
  }
}

export interface AlertMessage {
  type: StatusType;
  id: string;
  message: string;
  displaySeconds?: number;
}

export interface Notification {
  id: string;
  type: StatusType;
  title: string;
  message: string;
  closable: boolean;
}

export type StatusType = 'success' | 'error' | 'warning' | 'info';
