import {
  Directive,
  ElementRef,
  inject,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[evInvalid]',
})
export class InvalidCheckDirective implements OnChanges {
  #el = inject(ElementRef);
  #renderer = inject(Renderer2);

  @Input() evInvalid: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.evInvalid) {
      this.#renderer.addClass(this.#el.nativeElement, 'ev-invalid');
    } else {
      this.#renderer.removeClass(this.#el.nativeElement, 'ev-invalid');
    }
  }
}
