import { filter, OperatorFunction } from 'rxjs';

export function notNullFilter<T>(): OperatorFunction<T | null, T> {
  return filter(v => v !== null) as OperatorFunction<T | null, T>;
}

export function notUndefinedFilter<T>(): OperatorFunction<T | undefined, T> {
  return filter(input => input !== undefined) as OperatorFunction<T | undefined, T>;
}

export function notNullOrUndefined<T>(): OperatorFunction<null | undefined | T, T> {
  return filter(input => input !== null && input !== undefined) as OperatorFunction<
    null | undefined | T,
    T
  >;
}

export function notNullOrUndefinedOrEmptyString<T>(): OperatorFunction<null | undefined | T, T> {
  return filter(input => !!input) as OperatorFunction<null | undefined | string | T, T>;
}
