import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { PricePipe } from './price-pipe';

@NgModule({
  declarations: [PricePipe],
  imports: [CommonModule],
  exports: [PricePipe],
  providers: [CurrencyPipe, DecimalPipe],
})
export class PricePipeModule {}
