import { Injectable } from '@angular/core';
import { debounceTime, fromEvent, map, shareReplay, startWith } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  // In sync with atoms and ui-library's variables.scss
  public breakpointXSmall = 375;
  public breakpointSmall = 672;
  public breakpointMedium = 768;
  public breakpointLarge = 1056;
  public breakpointXLarge = 1312;
  public breakpoint2XLarge = 1584;

  public isMobileView$ = fromEvent(window, 'resize').pipe(
    startWith('init'),
    debounceTime(100),
    map(() => window.innerWidth <= this.breakpointSmall),
    shareReplay(1),
  );

  public isSmallerThanLarge$ = fromEvent(window, 'resize').pipe(
    startWith('init'),
    debounceTime(100),
    map(() => window.innerWidth < this.breakpointLarge),
    shareReplay(1),
  );

  public isDesktopView$ = this.isSmallerThanLarge$.pipe(
    map(isSmallerThenLarge => !isSmallerThenLarge),
  );
}
