export class CoreCookieService {
  public getCookie(name: string): string | null {
    // Split the document.cookie string into individual cookies
    const cookies = document.cookie.split(';');

    // Iterate over the cookies to find the one with the specified name
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i]?.trim();

      // Check if the cookie starts with the specified name
      if (cookie?.indexOf(name + '=') === 0) {
        // Extract and return the cookie value
        return cookie?.substring(name.length + 1, cookie?.length);
      }
    }

    // Return null if the cookie is not found
    return null;
  }
}
