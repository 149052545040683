import { Directive, inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[evRerender]',
})
export class RerenderDirective {
  #templateRef = inject(TemplateRef<any>);
  #viewContainer = inject(ViewContainerRef);

  // if detects changes of the input `val`, clear and rerender the view
  @Input() set evRerender(value: number | string) {
    this.#viewContainer.clear();
    this.#viewContainer.createEmbeddedView(this.#templateRef);
  }
}
