export enum PurchaseErrorsEnum {
  QuantityError = 'quantityError',
  Forbidden = 'Forbidden',
}

export const partialForbiddenSalesAreaUomSelectionErrorMessage =
  'is not authorized to set the unit of measure';

export type QuantityErrorMessage =
  `${PurchaseErrorsEnum.QuantityError}: ${string}/${string}/${string}`;
