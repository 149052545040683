import { HttpErrorResponse } from '@angular/common/http';
import { signal, WritableSignal } from '@angular/core';
import { finalize, Observable } from 'rxjs';

export class HttpSignalStore<T> {
  data: WritableSignal<T | undefined> = signal(undefined);
  loading = signal(false);
  error: WritableSignal<HttpErrorResponse | undefined> = signal(undefined);

  constructor(request$?: Observable<T>) {
    if (request$) {
      this.load(request$);
    }
  }

  load(request$: Observable<T>) {
    this.loading.set(true);
    request$.pipe(finalize(() => this.loading.set(false))).subscribe({
      next: data => this.data.set(data),
      error: error => this.error.set(error),
    });

    return this;
  }
}
