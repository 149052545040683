import { Injectable, signal } from '@angular/core';
import { FormControlOptions, ValidatorFn } from '@angular/forms';
import { firstValueFrom, Subject } from 'rxjs';

/**
 * Service for displaying dialogs in the BaseLayoutComponent
 */
@Injectable({
  providedIn: 'root',
})
export class DialogService {
  public alertDialogData = signal<AlertDialogData | null>(null);
  public alertDialogSubject = new Subject<boolean>();

  public confirmDialogData = signal<ConfirmDialogData | null>(null);
  public confirmDialogSubject = new Subject<boolean>();

  public promptDialogData = signal<PromptDialogData | null>(null);
  private promptDialogSubject = new Subject<string | null>();

  public alert(subtitle: string, title = $localize`Attention`): Promise<boolean> {
    this.alertDialogData.set({ subtitle, title });
    return firstValueFrom(this.alertDialogSubject);
  }

  public onAlertResult(acknowledged: boolean): void {
    this.alertDialogSubject.next(acknowledged);
    this.alertDialogData.set(null);
  }

  public confirm(subtitle: string, title = $localize`Please confirm`): Promise<boolean> {
    this.confirmDialogData.set({ subtitle, title });
    return firstValueFrom(this.confirmDialogSubject);
  }

  public onConfirmResult(confirmed: boolean): void {
    this.confirmDialogSubject.next(confirmed);
    this.confirmDialogData.set(null);
  }

  public prompt(
    subtitle: string,
    title = $localize`Dialog`,
    validators: ValidatorsType = null,
    label = '',
    placeholder = $localize`Type here...`,
    submitButtonText = $localize`OK`,
  ): Promise<string | null> {
    this.promptDialogData.set({
      label,
      placeholder,
      title,
      submitButtonText,
      subtitle,
      validators,
    });

    return firstValueFrom(this.promptDialogSubject);
  }

  public onPromptResult(value: string | null): void {
    this.promptDialogSubject.next(value);
    this.promptDialogData.set(null);
  }
}

export interface ConfirmDialogData {
  title: string;
  subtitle: string;
}

export interface AlertDialogData {
  title: string;
  subtitle: string;
}

export interface PromptDialogData {
  title: string;
  subtitle: string;
  label?: string;
  placeholder: string;
  submitButtonText: string;
  validators: ValidatorsType;
}

type ValidatorsType = ValidatorFn | ValidatorFn[] | FormControlOptions | null;
