export enum DocumentTypeNameEnum {
  AbsenceofSubstancesStatement = 'Absence of Substances - Statement',
  AflatoxinStatement = 'Aflatoxin - Statement',
  AllergensStatement = 'Allergens - Statement',
  AnimalTestingStatement = 'Animal Testing - Statement',
  ApplicationGuide = 'Application Guide',
  ArticlesinTechnicalJournals = 'Articles in Technical Journals',
  Booklet = 'Booklet',
  BranchSpecificStatement = 'Branch Specific Statement',
  Brochure = 'Brochure',
  BSEStatement = 'BSE - Statement',
  CADDetails = 'CAD Details',
  CaseStudy = 'Case Study',
  CLPStatement = 'CLP - Statement',
  CMRSubstancesStatement = 'CMR Substances - Statement',
  ColorCards = 'Color Cards',
  CompositionStatement = 'Composition - Statement',
  ConflictMineralsStatement = 'Conflict Minerals - Statement',
  ContaminantsStatement = 'Contaminants - Statement',
  ContentInformationStatement = 'Content Information Statement',
  DeclarationofPerformance_DoP = 'Declaration of Performance (DoP)',
  DeclarationCaliforniaProposition65Statement = 'Declaration California Proposition 65 - Statement',
  DioxinStatement = 'Dioxin - Statement',
  DualUseStatemets = 'Dual Use - Statemets',
  ECOCertificate = 'ECO Certificate',
  ElementalImpurities_PharmaQ3D_Statement = 'Elemental Impurities (Pharma Q3D) - Statement',
  EndocrineDisruptingCompoundsStatement = 'Endocrine Disrupting Compounds - Statement',
  EnvironmentalManagementSystemsStatement = 'Environmental Management Systems - Statement',
  EnvironmentalSelfAssessmentStatement = 'Environmental Self Assessment - Statement',
  EUEcolabelNordicSwanBlueAngelStatement = 'EU Ecolabel, Nordic Swan, Blue Angel - Statement',
  FamiQSCertificate = 'FamiQS - Certificate',
  FamiQSStatement = 'FamiQS - Statement',
  FDAInspectionReport = 'FDA Inspection Report',
  FDAStatement_Indirectfoodcontact = 'FDA - Statement (Indirect food contact)',
  FeedAdditivesCertificate = 'Feed Additives - Certificate',
  FireCertificate = 'Fire - Certificate',
  FlowChart = 'Flow Chart',
  Flyer = 'Flyer',
  FoodAdditiveStatement = 'Food Additive - Statement',
  FoodAllergensStatement = 'Food Allergens - Statement',
  FoodContactCertificate = 'Food Contact - Certificate',
  FoodContactComplianceStatement = 'Food Contact Compliance Statement',
  Formulation = 'Formulation',
  FSSC22000Certificate = 'FSSC 22000 - Certificate',
  GADSLStatement = 'GADSL - Statement',
  GeneralCertification = 'General - Certification',
  GeneralStatement = 'General - Statement',
  GenotoxicImpuritiesStatement = 'Genotoxic Impurities - Statement',
  GlutenStatement = 'Gluten - Statement',
  GMOStatement = 'GMO - Statement',
  GMPCertificate = 'GMP - Certificate',
  GOTSCertificates = 'GOTS - Certificates',
  HACCPCertificate = 'HACCP - Certificate',
  HalalCertificate = 'Halal - Certificate',
  HalalStatement = 'Halal - Statement',
  HeavyMetalStatement = 'Heavy Metal Statement',
  IATF16949Certificate = 'IATF 16949 - Certificate',
  ImpuritiesStatement = 'Impurities - Statement',
  Infographic = 'Infographic',
  InformationGuide = 'Information Guide',
  IrradiationStatement = 'Irradiation Statement',
  ISCCplus = 'ISCCplus - Certificate',
  ISCCplusFeedstockFeedstockOriginDeclarationBiomass = 'ISCCplus Feedstock Origin Declaration of Biomass Balanced Products',
  ISO_IEC17025Certificate = 'ISO/IEC 17025 - Certificate',
  ISO14001Certificate = 'ISO 14001 - Certificate',
  ISO22000Certificate = 'ISO 22000 - Certificate',
  ISO45001Certificates = 'ISO 45001 - Certificates',
  ISO50001Certificate = 'ISO 50001 - Certificate',
  ISO9001Certificate = 'ISO 9001 - Certificate',
  JatrophaStatement = 'Jatropha - Statement',
  JSQIcomplianceStatement = 'JSQI compliance - Statement',
  KosherCertificate = 'Kosher - Certificate',
  Label_Product = 'Label (Product)',
  LactoseStatement = 'Lactose - Statement',
  LatexStatement = 'Latex - Statement',
  ManufacturingStatement = 'Manufacturing - Statement',
  MelamineStatement = 'Melamine - Statement',
  MetalStatement = 'Metal - Statement',
  MicrobiologicalStatement = 'Microbiological - Statement',
  MRSL_ZDHC_ComplianceStatement = 'MRSL (& ZDHC) Compliance - Statement',
  NagoyaProtocolStatement = 'Nagoya Protocol - Statement',
  NanomaterialStatement = 'Nanomaterial - Statement',
  NaturalcontentStatement = 'Natural content - Statement',
  NaturalLatexStatement = 'Natural Latex - Statement',
  News = 'News',
  NitrosamineStatement = 'Nitrosamine - Statement',
  NoticeofchangesatproductionprocessStatement = 'Notice of changes at production-process - Statement',
  NutritionalInformationStatement = 'Nutritional Information - Statement',
  OekoTexStandard100Certificate = 'Oeko-Tex Standard 100 - Certificate',
  OnePagePromotion = 'One Page Promotion',
  OriginStatement = 'Origin - Statement',
  PalmkernelStatement = 'Palm kernel - Statement',
  PesticidesStatement = 'Pesticides - Statement',
  PfasStatement = 'PFAS – Statement',
  PharmaRegulationStatement = 'Pharma Regulation Statement',
  PharmaPackagingEuStatement = 'Pharma Packaging EU - Statement',
  PharmaPackagingUsStatement = 'Pharma Packaging US - Statement',
  PhthalatesStatement = 'Phthalates - Statement',
  PhthalateStatement = 'Phthalate - Statement',
  POPStatement = 'POP - Statement',
  Poster = 'Poster',
  ProcessingDataSheet = 'Processing Data Sheet',
  ProductCarbonFootprintStatement = 'Product Carbon Footprint – Statement',
  ProductDataSheet = 'Product Data Sheet',
  ProductInformation = 'Product Information',
  ProductSafetyAssessmentofCV127Soybean = 'Product Safety Assessment of CV127 Soybean',
  ProductSafetyRegulatoryandCompliance = 'Product Safety, Regulatory and Compliance',
  ProductSpecification = 'Product Specification',
  QualityManagementSystemStatement = 'Quality Management System - Statement',
  RangeChart = 'Range Chart',
  REACHComplianceCertificate = 'REACH Compliance - Certificate',
  REACHComplianceStatement = 'REACH Compliance - Statement',
  REDCert = 'REDcert - Certificate',
  REDCertFeedstockFeedstockOriginDeclarationBiomass = 'REDcert Feedstock Origin Declaration of Biomass Balanced Products',
  RegistrationStatement = 'Registration - Statement',
  RegulationStatement = 'Regulation - Statement',
  RegulatoryStatement = 'Regulatory Statement',
  RenewableCarbonStatement = 'Renewable Carbon - Statement',
  ResidualSolvents_PharmaQ3C_Statement = 'Residual Solvents (Pharma Q3C) - Statement',
  ResidualSolventsStatement = 'Residual Solvents - Statement',
  RoHSDirectiveStatement = 'RoHS Directive - Statement',
  RouteofsynthesisStatement = 'Route of synthesis - Statement',
  RSPOStatement = 'RSPO - Statement',
  SafetyDataSheet = 'Safety Data Sheet',
  SafetyStatement = 'Safety - Statement',
  SensitizationDeclarations = 'Sensitization - Declarations',
  ShelfLifeStatement = 'Shelf Life - Statement',
  ShelflifeStatement = 'Shelf life - Statement',
  SiliconeStatement = 'Silicone - Statement',
  SiteofManufacturerStatement = 'Site of Manufacturer - Statement',
  SolventsStatement = 'Solvents - Statement ',
  StyrodurApprovals = 'Styrodur Approvals',
  SustainabilityActivities = 'Sustainability activities',
  SVHC_REACH_Statement = 'SVHC (REACH) - Statement',
  TechnicalDataSheet = 'Technical Data Sheet',
  TechnicalInformation = 'Technical Information',
  TechnicalInformationBulletin_TIB = 'Technical Information Bulletin (TIB)',
  ToxStatement = 'Tox - Statement ',
  TscaPbtStatement = 'TSCA PBT - Statement',
  ValueCard = 'Value Card',
  VDECertificate = 'VDE - Certificate',
  VeganStatement = 'Vegan - Statement',
  VolatileOrganicCompoundsStatement = 'Volatile Organic Compounds - Statement',
  WADAStatement = 'WADA - Statement',
  WEEEStatement = 'WEEE - Statement',
}
