import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { PurchaseErrorsEnum } from '@ev-portals/ev/shared';
import { isAfter, isBefore, isSameDay, startOfDay, startOfToday } from 'date-fns';

export const integerValidator = Validators.pattern(/^[0-9]+$/);

export const dateFormatValidator = Validators.pattern(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/);

export const quantityValidators = [Validators.required, Validators.min(1), integerValidator];

export function numberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const valid = !isNaN(parseFloat(control.value)) && isFinite(control.value);
    return valid ? null : { notANumber: true };
  };
}

export function checkedValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value && control.value.checked ? null : { notCheckedError: true };
  };
}

export function futureDateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // Check, whether a date string like this '2022-12-22' is in the future
    const isFutureDate = isAfter(startOfDay(new Date(control.value)), startOfToday());
    return isFutureDate ? null : { notFutureDateError: true };
  };
}

export function dateNotInPastValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // When clear button is clicked on Calander selection view, it sends empty string
    if (control.value === '') return null;

    const isPastDate = isBefore(new Date(control.value), startOfToday());
    return isPastDate ? { pastDateError: true } : null;
  };
}

export function hasIdValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value?.id ? null : { noIdAvailable: { value: control.value } };
  };
}

export function dateRangeValidator(): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const fromDate = formGroup.value?.from;
    const toDate = formGroup.value?.to;

    if (fromDate && toDate) {
      const sameDay = isSameDay(fromDate, toDate);
      const startBeforeEnd = isBefore(fromDate, toDate);
      return sameDay || startBeforeEnd ? null : { dateRangeError: true };
    }

    return null;
  };
}

export function quantityValidator(packageSize: number, decimals: number, uom: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // bulk types and uoms we want to disable have value 0
    if (packageSize === 0) return null;

    const packageCount = Math.round((control.value / packageSize) * 10000000) / 10000000;

    // 1. if value entered is multiple of package size, then no error
    const valueIsMultipleOfPackageSize = packageCount % 1 === 0;
    if (valueIsMultipleOfPackageSize) {
      return null;
    }

    // 2. if value entered is less than package size, then error (show minimum possible value)
    if (control.value < packageSize) {
      return {
        [PurchaseErrorsEnum.QuantityError]:
          $localize`Quantity must be at least` + ` ${+packageSize.toFixed(decimals)} ${uom}.`,
      };
    }

    // 3. if value entered is greater than package size, then show closest possible values
    const lowerBound = (Math.floor(control.value / packageSize) * packageSize).toFixed(decimals);
    const upperBound = (Math.ceil(control.value / packageSize) * packageSize).toFixed(decimals);

    return {
      [PurchaseErrorsEnum.QuantityError]:
        $localize`Closest possible values are either` +
        ` ${parseFloat(lowerBound)} ${uom} ` +
        $localize`or` +
        ` ${parseFloat(upperBound)} ${uom}.`,
    };
  };
}

export function invalidPoItemNumberValidator(invalidPoItemNumbers?: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return invalidPoItemNumbers?.includes(control.value) ? { invalidPoItemNumber: true } : null;
  };
}

export function timeRangeValidator(): ValidatorFn {
  return (control: AbstractControl<TimeRangeForm>): ValidationErrors | null => {
    const timeFrom = control.value.timeFrom;
    const timeTo = control.value.timeTo;

    if (timeFrom && timeTo) {
      const [hoursFrom, minutesFrom] = timeFrom.split(':');
      const [hoursTo, minutesTo] = timeTo.split(':');
      const dateFrom = new Date();
      dateFrom.setHours(Number(hoursFrom));
      dateFrom.setMinutes(Number(minutesFrom));
      const dateTo = new Date();
      dateTo.setHours(Number(hoursTo));
      dateTo.setMinutes(Number(minutesTo));
      const timeFromIsBefore = isBefore(dateFrom, dateTo);
      return timeFromIsBefore ? null : { timeRangeError: true };
    }

    return null;
  };
}

export function positiveFloatValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    return value > 0 ? null : { notValidPositiveFloatValue: true };
  };
}

export interface TimeRangeForm {
  timeFrom: string | null;
  timeTo: string | null;
}
